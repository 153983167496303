import React from 'react';
import { useRouter } from 'next/router';
import { MUZOLOGY_SIGNUP_URL } from '@/lib/defaults';

export const ConversionButtons = (props) => {
    const router = useRouter();
    return (
        <div className='conversion-buttons'>
            <div className='conversion-buttons--container'>
                <div className='title'> Let's Make Math a #1 Hit! 🤩</div>
                <div className='subheader'> Start your FREE trial today.</div>

                <div className='buttons'>
                    <button className='signup-button educator' onClick={() => router.push('/signup', undefined, { shallow: true })}>
                        EDUCATOR SIGN UP
                    </button>
                    <button className='signup-button parent' onClick={() => router.push(MUZOLOGY_SIGNUP_URL, undefined, { shallow: true })}>
                        PARENT SIGN UP
                    </button>
                    {/*
					<button
						className={'signup-button student'}
						onClick={() => router.push('/signup?role=student', undefined, { shallow: true })}
					>
            Student Sign Up
					</button>
*/}
                </div>
            </div>

            <style jsx>
                {`
                    .conversion-buttons {
                        background: white url('/assets/backgrounds/conversion-buttons-background.png') round;
                        //width: 1920px;
                        //height: 337px;
                        //background-repeat: round;
                        background-size: cover;
                        //background-size: cover;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 0 35px;

                        @media (max-width: 1100px) {
                            background-repeat: no-repeat;
                            background-size: cover;
                        }

                        .conversion-buttons--container {
                            margin: 36px auto;

                            .title {
                                margin-top: 44px;
                                font-family: Avenir, 'Avenir', sans-serf, serif;
                                font-style: normal;
                                font-weight: 800;
                                font-size: 42px;
                                line-height: 48px;
                                text-align: center;
                                color: #ffffff;

                                @media (max-width: 575px) {
                                    font-size: 26px;
                                    width: 100%;
                                }
                            }

                            .subheader {
                                margin-top: 10px;
                                font-family: Avenir, 'Avenir', sans-serf, serif;
                                font-style: normal;
                                font-weight: 500;
                                font-size: 26px;
                                line-height: 28px;
                                text-align: center;
                                color: #ffffff;
                            }

                            .buttons {
                                padding-left: 9rem;
                                padding-right: 9rem;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                font-size: 20px;

                                @media (max-width: 1400px) {
                                    padding-left: 7rem;
                                    padding-right: 7rem;
                                }
                                @media (max-width: 1100px) {
                                    padding: 1.5em 5rem;
                                }
                                @media (max-width: 995px) {
                                    flex-direction: column;
                                }
                                @media (max-width: 500px) {
                                    font-size: 16px;
                                }

                                .signup-button {
                                    margin: 34px 0 50px;
                                    border: none;
                                    white-space: nowrap;
                                    border-radius: 30px;
                                    padding: 19px 80px;

                                    font-family: Avenir, 'Avenir', sans-serf, serif;
                                    font-style: normal;
                                    font-weight: 800;
                                    font-size: 22px;

                                    line-height: 27px;
                                    color: #121651;

                                    @media (max-width: 1400px) {
                                        padding: 19px 40px;
                                    }
                                    @media (max-width: 1050px) {
                                        font-size: 18px;
                                        margin: 20px 0 20px;
                                    }
                                    @media (max-width: 575px) {
                                        padding: 19px 10px;
                                        font-size: 16px;
                                        width: 100%;
                                    }
                                    @media (max-width: 575px) {
                                        font-size: 14px;
                                    }

                                    &.educator {
                                        background: linear-gradient(135deg, #e637a8 2.69%, #e68137 75%);

                                    }

                                    &.parent {
                                        background: linear-gradient(276.01deg, #1cc8e1 11.84%, #1c9ae1 85.52%);
                                        margin-left: 44px;
                                        margin-right: 44px;
                                        @media (max-width: 575px) {
                                            margin-top: 10px;
                                        }
                                    }

                                    &.student {
                                        background: linear-gradient(95.22deg, #7169e7 19.55%, #a09afd 88.97%);
                                        @media (max-width: 575px) {
                                            margin-top: 10px;
                                        }
                                    }

                                    &:hover {
                                        &.educator {
                                            background: #e637a8;
                                        }

                                        &.parent {
                                            background: #1c9ae1;
                                        }

                                        &.student {
                                            background: #a09afd;
                                        }
                                    }

                                    &:active {
                                        &.educator {
                                            background: linear-gradient(180deg, #e637a8 0%, #d11f4a 100%);
                                        }

                                        &.parent {
                                            background: linear-gradient(180deg, #1c9ae1 0%, #0c6ca2 100%);
                                        }

                                        &.student {
                                            background: linear-gradient(95.22deg, #7169e7 19.55%, #4e45d1 88.97%);
                                        }
                                    }

                                    &:focus {
                                        outline: none;
                                        box-shadow: 0 0 8px #1cc8e1;
                                    }
                                }
                            }

                            background: #121651;
                            border-radius: 20px;

                            @media (max-width: 390px) {
                                border-radius: 0;
                            }
                        }
                    }
                `}
            </style>
        </div>
    );
};

export default ConversionButtons;

import React from 'react';
import { ScaleLoader } from "react-spinners";

const SubmitButton = ({ id = null, text, onClick = null, isLoading = false, className = '', href = null }) => {
    let style = {};
    if (isLoading) {
        style = {
            pointerEvents: 'none', cursor: 'not-allowed', '&:hover': {
                boxShadow: 'none', transform: 'none',
            }
        };
    }
    return (
        <button
            id={id ?? 'submit'}
            type='submit'
            disabled={isLoading}
            onClick={onClick}
            className={className + ' w-full max-w-[280px] m-auto h-14 sm:h-[62px] p-0 py-1 bg-[#6F5FE6] rounded-2xl border-solid border-2 sm:border-white justify-center items-center gap-4 inline-flex hover:bg-indigo-600 hover:border-indigo-600 hover:shadow-lg hover:scale-105 transform transition-all duration-300 ease-in-out active:bg-indigo-700 active:border-indigo-700 active:shadow-none active:scale-100'}
            style={style}
        >
            {isLoading ? <ScaleLoader
                color='white'
                // radius={10} height={20}
                // height={20}
            /> : <div className='text-[#FCFCFC] text-center text-neutral-50 text-lg sm:text-[20px] font-semibold font-Poppins leading-loose tracking-wide'>
                {text}
            </div>}
        </button>
    );
};

export default SubmitButton;

import { motion } from 'framer-motion';

export const FloatUpDown = ({ children, delay = 0, distance = -5 }: any) => {
    return (
        <motion.div
            animate={{
                y: [0, distance, 0]
            }}
            transition={{
                duration: 2,
                repeat: Infinity,
                repeatType: 'reverse',
                delay
            }}
        >
            {children}
        </motion.div>
    );
};

import React from 'react';

const TestimonialQuote = ({ name, className, text }) => {
    const gradientId = `gradient-${Math.random().toString(36).substr(2, 9)}`;
    return (
        <div className={`${className} flex justify-center`}>
            <div>
                <svg className='w-[65.8px] h-[45.5px]' viewBox='0 0 94 65' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path
                        d='M20.5324 31.2114H22.1365C27.4835 31.2114 31.975 32.8341 35.6109 36.0793C39.2469 39.3245 41.0648 43.3333 41.0648 48.1057C41.0648 52.8781 39.2469 56.8869 35.6109 60.1322C31.975 63.3774 26.8419 65 20.2116 65C13.7952 65 8.76906 62.5184 5.13311 57.5551C1.71104 52.4009 0 45.9104 0 38.0837C0 24.9119 3.31513 15.2717 9.94539 9.163C16.5757 3.05433 25.0239 0 35.2901 0V13.7445C31.4403 13.7445 28.0182 15.0808 25.0239 17.7533C22.0296 20.2349 20.5324 23.9574 20.5324 28.9207V31.2114ZM73.4676 31.2114H75.0717C80.4187 31.2114 84.9101 32.8341 88.5461 36.0793C92.182 39.3245 94 43.3333 94 48.1057C94 52.8781 92.182 56.8869 88.5461 60.1322C84.9101 63.3774 79.777 65 73.1468 65C66.7304 65 61.7042 62.5184 58.0683 57.5551C54.6462 52.4009 52.9352 45.9104 52.9352 38.0837C52.9352 24.9119 56.2503 15.2717 62.8805 9.163C69.5108 3.05433 77.959 0 88.2253 0V13.7445C84.3754 13.7445 80.9534 15.0808 77.959 17.7533C74.9647 20.2349 73.4676 23.9574 73.4676 28.9207V31.2114Z'
                        fill={`url(#${gradientId})`}
                    />
                    <linearGradient id={gradientId} x1='7.51071e-07' y1='37.5' x2='68.8814' y2='62.9912' gradientUnits='userSpaceOnUse'>
                        <stop stopColor='#7322B8' />
                        <stop offset='1' stopColor='#80F3E8' />
                    </linearGradient>
                </svg>
            </div>
            <div className='mx-5 text-base lg:text-xl font-normal text-white sm:text-black '>
                <div>{text}</div>
                {name && <div className='mt-0 italic'>-{name}</div>}
            </div>
        </div>
    );
};

export default TestimonialQuote;

import React, { useCallback, useState } from 'react';
import CheckoutBox from './components/CheckoutBox';
import { Image as DatoImage } from 'react-datocms';
import BalanceText from '@/components/BalanceText/BalanceText';
import cx from 'classnames';
import TestimonialQuote from './components/TestimonialQuote';
import TitleSection from './components/TitleSection';
import CheckoutImage from './components/CheckoutImage';
import TeacherMobileFooter from './components/TeacherMobileFooter';
import AwardsMobileFooter from './components/AwardsFooter';
export const CheckoutScreen = ({ testimonial = null, timesTables = null, children, title, subHeader, text, activeTab, teacher = false, awardImages = [] }) => {
    let containerClassName = 'w-full mx-auto max-w-[550px] sm:max-h-[500px] ';
    if (activeTab === 1) containerClassName = 'w-full mx-auto max-w-[650px]  ';
    containerClassName = 'w-full mx-auto  ';

    return (
        <div className='flex flex-col w-full h-full relative'>
            <TitleSection className='block lg:hidden mb-4 sm:mb-6 red-' title={title} subHeader={subHeader} text={text} teacher={teacher} timesTables={timesTables}/>

            <div className='mt-0 md:mt-6 w-full flex items-center justify-center relative '>
                <div className='flex flex-col-reverse lg:flex-row w-full sm:gap-8 xl:gap-14 sm:min-h-[900px] '>
                    <CheckoutImage timesTables={timesTables}/>
                    <div
                        className={cx('relative h-full grow w-full hidden sm:flex items-start  sm:bg-none bg-cover ', {
                            'bg-checkout-background-one-mobile ': activeTab === 0,
                            'bg-checkout-background-two-mobile': activeTab === 1,
                            'py-8 sm:py-0': !teacher,
                            'bg-none py-0 ': teacher
                        })}
                    >
                        <div className=' w-full px-8 lg:px-0 pt-0 md:pt-0 pb-0 md:pb-20 hidden lg:flex flex-col gap-4'>
                            <TitleSection teacher={teacher} className='hidden lg:block' title={title} subHeader={subHeader} text={text} timesTables={timesTables}/>

                            {testimonial && <TestimonialQuote className='lg:p-0' text={testimonial.text} name={testimonial.name} />}
                        </div>
                    </div>

                    <div className='w-full flex items-start'>
                        <div className={`${containerClassName} `}>
                            <div className=' '>
                                <CheckoutBox>
                                    <div className='mx-auto w-full font-Poppins'>{children}</div>
                                </CheckoutBox>
                                {teacher && <TeacherMobileFooter />}
                                {!teacher && <AwardsMobileFooter awardImages={awardImages} timesTables={timesTables}/>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CheckoutScreen;

import { motion } from 'framer-motion';

export const FloatAround = ({ children, delay = 0 }:any) => {
  return (
    <motion.div
      animate={{
        scale: [0.8, 0.9, 0.8],
      }}
      transition={{
        duration: 3,
        repeat: Infinity,
        repeatType: 'reverse',
        delay,
      }}
    >
      {children}
    </motion.div>
  );
};

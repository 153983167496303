import axios from 'axios';
import { LOGROCKET_ID } from '@/lib/defaults';
import LogRocket from 'logrocket';
import { handleRedirection } from '@/lib/redirect';

// step 1: user enters an email to begin the checkout process

interface BeginCheckoutProps {
    email: string;
    anonymous_id?: string,
    logrocket_session_url?: string,
    metadata?: any;
}

const getLogrocketSessionUrl = async () => {
    if (LOGROCKET_ID) {
        console.log('LogRocket - getting session url', LogRocket);
        return LogRocket.getSessionURL((sessionURL: string) => sessionURL);
    }
    // if (window?.LogRocket) {
    //     const { LogRocket } = window;
    //     if (LogRocket) {
    //     }
    // }
    return null;
};

function setPersistentCookie(name, value, days) {
    let expires = '';
    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = '; expires=' + date.toUTCString();
    }
    document.cookie = name + '=' + (value || '') + expires + '; path=/';
}

function deleteCookie(name) {
    setPersistentCookie(name, '', -1);
}

export const writeToLocalStorage = (key, value) => {
    const serializedValue = JSON.stringify(value);
    if (localStorage) localStorage.setItem(key, serializedValue);
};
export const deleteLocalStorage = (key) => {
    if (localStorage) localStorage.removeItem(key);
};

export const beginCheckout = async (data: BeginCheckoutProps) => {

    // get the logrocket session url
    const logRocketSessionUrl = await getLogrocketSessionUrl();

    // clear any existing checkout state
    deleteLocalStorage('checkout-state');

    // begin checkout
    const payload = {
        ...data,
        metadata: {
            ...(data.metadata ?? {}),
            logRocketSessionUrl
        }
    };

    // const data = {
    //     email,
    //     metadata: {
    //         ...metadata,
    //         logRocketSessionUrl
    //     }
    // };
    return axios.post('/api/checkout/begin/', payload, { withCredentials: true })
        .then((res) => {
            console.log('response', res);
            // return the token in the response
            // get the cookies
            console.log('response', res.data);
            console.log('cookies', res.headers['set-cookie']);

            // check the response for the token
            // const { token } = res.data;
            // if (token) {
            //     // save the checkout state to local storage
            //     console.log("Saving muzology-checkout state", res.data);
            //     writeToLocalStorage('checkout-state', { pathname, ...res.data });
            //     // console.log("Saving muzology-checkout cookie", token);
            //     // setPersistentCookie('muzology-checkout', token, 7);
            // } else {
            //     // deleteCookie('muzology-checkout');
            // }
            return res.data;
        })
        .catch((err) => {
            // there was an error
            console.error('Begin checkout error', err);
            if (err.response?.status === 400) {
                if (typeof err.response?.data === 'object') {
                    console.log(err.response.data);
                    // for (const [key, value] of Object.entries(err.response.data))
                    // setError(key, { type: 'manual', message: value.toString() });
                    // return the errors
                    return { 'errors': err.response.data };
                }
            } else {
                console.error(err);
                return { 'error': err };
            }

        });
};

// step 2: the user sets up their account (username, password, etc)

interface SetupAccountProps {
    token: string;
    email?: string;
    username: string;
    password: string;
    grade: string;
    metadata?: any;
}

export const setupAccount = async (data: SetupAccountProps) => {

    return axios.post('/api/checkout/begin/', data)
        .then((res) => {
            console.log('res', res.data);
            // setId(res.data.clientSecret);
            // setActiveTab(1);
            return res.data.clientSecret;
        })
        .catch((err) => {
            console.log('err', err);
            if (err.response?.status === 400) {
                if (typeof err.response?.data === 'object') {
                    console.log(err.response.data);
                    // set form errors
                    for (const [key, value] of Object.entries(err.response.data)) {
                        console.log(key);
                        console.log(value.toString());
                        // setError(key, { type: 'manual', message: value.toString() });
                    }
                }
            } else {
                console.error(err);
            }

        });

};

// step 3: the user completes the purchase (stripe, paypal, etc.) and the checkout is complete
export const completeCheckout = async (name, planId, setupIntent, anonymousId) => {
    /* eslint-disable camelcase */
    const data = {
        anonymousId,
        name,
        plan_id: planId,
        setup_intent: setupIntent
    };

    /* eslint-enable camelcase */
    return axios.post('/api/checkout/complete/', data)
        .then((response) => {
            console.log('checkout complete response', response);
            if (!handleRedirection(response)) {
                console.error("Error handling redirection", response);
                return response.data;
            }
            // const { redirect } = res.data;
            // window.location.href = redirect;
            // return res.data;
        })
        .catch((err) => {
            // there was an error, this should not happen
            console.log('Error submitting checkout', err);
            const code = err.response?.status;
            if (code === 302) {
                // this is a redirect
                const location = err.response?.headers?.location;
                console.log('checkout success, redirecting', location);
                handleRedirection(err.response);
            } else {
                console.error(err);
                const message = err.response?.data?.message;
                alert('There was an error completing the checkout, please contact support (' + message + ')');
            }
        });
};


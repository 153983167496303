import React from 'react';

const CheckoutBox = ({ children, header = null, subHeader = null, smallSubHeader = null }) => {
    return (
        <div
            className=' sm:rounded-[18px] bg-white font-Poppins w-full px-4 tiny:px-5 xs:px-8 sm:px-4 lg:px-6 pb-5 sm:pb-8 sm:py-8 sm:max-w-[700px] m-auto overflow-clip sm:shadow-checkout'>
            {header && <div className='sm:mb-3  text-[#303030] text-center font-semibold text-2xl md:text-[31px]'>{header}</div>}
            {subHeader && <div className='text-[#4E4E4E] font-normal text-center text-base md:text-2xl'>{subHeader}</div>}
            {smallSubHeader && <div className='text-[#4E4E4E] font-normal text-center text-[17px]'>{smallSubHeader}</div>}
            {children}
        </div>
    );
};

export default CheckoutBox;

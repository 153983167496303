import React, { useEffect, useState, useRef } from 'react';
import balanceText from 'balance-text';
import PropTypes from 'prop-types';
import cx from "classnames";

const BalanceText = ({ as = 'div', children = null, text = null, style = {}, className = '', resize = true }) => {
    // do not show text until after the initial resize
    const [visible, setVisible] = useState(false);
    const containerRef = useRef(null);

    useEffect(() => {
        const handleResize = () => {
            if (!resize) {
                return;
            }
            balanceText(containerRef.current);
        };

        setVisible(true);

        // add a resize listener
        window.addEventListener('resize', handleResize);

        // trigger a balanceText after the initial render
        setTimeout(() => balanceText(containerRef.current), 0);

        // remove the resize listener
        return () => window.removeEventListener('resize', handleResize);

    }, [resize]);

    useEffect(() => {
        balanceText(containerRef.current);
    });

    const combinedStyle = {
        ...style,
        visibility: visible ? 'visible' : 'hidden',
    };

    const As = as;

    // NOTE: balanceText will not work if it is the root element of a component
    return (
        <div>
            <As ref={containerRef} className={cx(className, { 'hidden': !visible })}>
                {children || text}
            </As>
        </div>
    );
};

// BalanceText.propTypes = {
//     text: PropTypes.string,
//     children: PropTypes.any,
//     className: PropTypes.string,
//     style: PropTypes.oneOfType([
//         PropTypes.arrayOf(PropTypes.any),
//         PropTypes.any,
//     ]),
//     resize: PropTypes.bool,
// };

export default BalanceText;

import React from 'react';
import Image from 'next/legacy/image';
import PlatRecord from '@/assets/checkout/Plat_Record.png';
import RecordBackground from '@/assets/checkout/Record_Background.png';
import CheckoutChild from '@/assets/timestables/checkout/CheckoutChild2.png';

const CheckoutImage = ({ timesTables }) => {
    return (
        <>
            {timesTables && (
                <div className=' hidden lg:block absolute bottom-[0%] left-[5%]  z-20 '>
                    <Image src={CheckoutChild} className='h-auto sm:h-full sm:max-h-[100%] w-full max-w-none  sm:max-w-[100%]' />
                </div>
            )}

            {!timesTables && (
                <>
                    <div className=' hidden lg:block absolute bottom-[10%] left-[5%]  z-20 max-h-[310px] max-w-[310px]'>
                        <Image src={PlatRecord} className='h-auto sm:h-full sm:max-h-[100%] w-full max-w-none  sm:max-w-[100%]' />
                    </div>
                    <div className='hidden lg:block  absolute bottom-[4%] xl:bottom-[2%] left-[1%] xl:left-[-1%] z-10 max-h-[488px] max-w-[500px] xl:max-w-[611px] '>
                        <Image src={RecordBackground} className='h-auto sm:h-full sm:max-h-[100%] w-full max-w-none  sm:max-w-[100%]' />
                    </div>
                </>
            )}
        </>
    );
};

export default CheckoutImage;

import React from 'react';

const TeacherMobileFooter = () => {
    return (
        <div className='bg-gradient-to-tr from-[#EE86B4] to-[#D964EA] md:bg-none py-12 block sm:hidden'>
            <div className='text-white text-center max-w-[293px] mx-auto sm:max-w-full'>
                {' '}
                <span className='font-semibold'>Don’t Have a Muzology Account? </span>
                <a href={`/`}>
                    <span className='underline text-white'>Click here</span>
                </a>{' '}
                to sign up.{' '}
            </div>
            <div className='text-white mt-5 text-center max-w-[297px] mx-auto sm:max-w-full'>
                <span className='font-semibold'>Need Help?</span> <br /> Contact us at{' '}
                <a href='mailto:info@muzology.com' className='text-white no-underline'>
                    <span> support@muzology.com</span>
                </a>
            </div>
        </div>
    );
};

export default TeacherMobileFooter;

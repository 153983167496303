import React from 'react';
import BalanceText from '@/components/BalanceText/BalanceText';
import cx from 'classnames';

const TitleSection = ({ teacher, className, subHeader, title, text, timesTables }) => {
    return (
        <div className={cx(`text-left m-auto w-full pt-4 sm:pt-0 px-4 ${className}`, { 'bg-[#F7FAFC] py-10': teacher })}>
            <div
                className={cx(
                    "block text-[#4E4E4E] text-lg sm:text-xl font-normal font-['Open Sans'] capitalize leading-loose text-center lg:text-left",
                    { block: !teacher },
                    { 'mb-1 font-normal  text-[#4E4E4E]': teacher },
                  
                )}
            >
                {subHeader}
            </div>
            <div className={cx('hidden sm:block Header lg:mb-[40px] text-center lg:text-left text-[#303030] text-[25px] md:text-[40px] font-semibold font-Poppins leading-[1.33] md:leading-[47.60px]' )}>
                {title}
            </div>
            <BalanceText
                className={cx(
                    'block sm:hidden Header md:mb-[40px] text-center lg:text-left text-[#303030] text-[28px] md:text-[40px] font-semibold font-Poppins leading-[1.33] md:leading-[47.60px]',
                     {'text-[30px]' : timesTables}
                )}
            >
                {title}
            </BalanceText>
            {text && <div className='text-[#4E4E4E] font-normal text-[17px]'>{text}</div>}
        </div>
    );
};

export default TitleSection;

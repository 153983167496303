import React from 'react';
import { FloatUpDown } from '@/components/Animations/FloatUpDown';

export interface Icon {
    src?: string;
    image?: string;

    top?: number | string;
    bottom?: number | string;
    left?: number | string;
    right?: number | string;

    rotate?: number | string;
    scale?: number | string;
    opacity?: number | string;

    className?: string;
}

interface IconBlockProps {
    icons: Icon[];
    className?: string;
    distance?: number;
    delay?: number;
}

export const floatingIcon = (left, top, image, className = '', rotate = 0, scale = 1, opacity = 1) => {
    return {
        left,
        top,
        image,
        className,
        scale,
        rotate,
        opacity
    };
};

export const IconBlocks = ({ blocks }) => (
    <>
        {blocks?.map((block, index) => {
            return (
                <IconBlock key={index} icons={block.icons} className={block.className} />
            );
        })}
    </>
);
export const IconBlock = ({ icons, className = '', distance = null, delay = null }: IconBlockProps) => {
    return (
        <div className={`overflow-visible  ghost-inspector-hide z-40 absolute inset-0 pointer-events-none ${className}`} aria-hidden='true'>
            {icons?.map((icon, index) => (
                <div key={index} style={{
                    position: 'absolute',
                    top: icon.top,
                    left: icon.left,
                    right: icon.right,
                    bottom: icon.bottom,
                    opacity: icon.opacity,
                    transform: `rotate(${icon.rotate ?? 0}deg) scale(${icon.scale ?? 1})`
                }}>
                    <FloatUpDown delay={delay ?? Math.random()} distance={distance ?? 6}>
                        <img
                            src={icon.src ?? icon.image ?? '/assets/icons/icon-line-heart.svg'}
                            className={icon.className}
                            aria-hidden='true'
                            alt='Floating Icon'
                        />
                    </FloatUpDown>
                </div>
            ))}
        </div>
    );
};

export default IconBlock;

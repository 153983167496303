import React from 'react';
import cx from 'classnames';
import Image from 'next/legacy/image';
import CheckoutChildMobile from '@/assets/timestables/checkout/CheckoutChildMobile2.png';

const AwardsFooter = ({ awardImages = [], timesTables = null }) => {
    const awardsRow1 = awardImages.slice(0, 2);
    const awardsRow2 = awardImages.slice(2, 5);

    return (
        <>
            {timesTables && (
                <div className='bg-gradient-to-tr from-[#4E389D] to-[#4E389D] text-white py-4 sm:py-12 px-3 sm:px-5 block sm:hidden'>
                    <div className='flex items-center'>
                        <div className='w-full'>
                            <Image src={CheckoutChildMobile} className='h-auto sm:h-full sm:max-h-[100%] w-full max-w-none  sm:max-w-[100%]' />
                        </div>
                        <div className='max-w-[250px] ml-3'>
                            <span className='font-semibold'>"Learning multiplication tables has never been easier.</span> I wish these videos were around when I
                            was a kid. Say goodbye to piles of flashcards."
                            <br />
                            <br />
                            <span className='italic'>- Danielle N, parent</span>
                            <br />
                            <span className='italic'>(Miami, FL)</span>
                        </div>
                    </div>
                </div>
            )}

            {!timesTables && (
                <div className='bg-gradient-to-tr from-[#EE86B4] to-[#D964EA] md:bg-none  py-14 pb-16 px-5 hidden'>
                    <div className='flex justify-center items-center w-full mx-3'>
                        {awardsRow1?.map((logo, index) => (
                            <div key={`${logo.url}-${index}`} className={cx('flex justify-center')}>
                                <img src={logo?.url} alt={logo?.alt ?? logo?.title} loading='lazy' className={cx('w-[175px] xs:w-[195px] h-[70px]')} />
                            </div>
                        ))}
                    </div>
                    <div className='flex justify-center items-center gap-2 w-full mt-6 px-2'>
                        {awardsRow2?.map((logo, index) => (
                            <div key={`${logo.url}-${index}`} className={cx(' flex justify-center')}>
                                <img src={logo?.url} alt={logo?.alt ?? logo?.title} loading='lazy' className={cx('w-[100px] xs:w-[120px] h-[70px]')} />
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </>
    );
};

export default AwardsFooter;
